/* $social-button
 ------------------------------------------*/

$bs-height-base: 2.375rem;
$bs-height-lg: 3.25rem;
$bs-height-sm: 2.125rem;
@mixin btn-icon() {
  position: relative;
  overflow: hidden;
  min-height: $bs-height-base;
  padding-left: ($bs-height-base + $padding-base-horizontal);
  text-overflow: ellipsis;
  :first-child {
    line-height: $bs-height-base;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: $bs-height-base;
    text-align: center;
    border-right: 1px solid rgba(0, 0, 0, .03);
    background: rgba(0, 0, 0, .05);
  }
  &.btn-lg {
    min-height: $bs-height-lg;
    :first-child {
      line-height: $bs-height-lg;
      width: $bs-height-lg;
    }
  }
  &.btn-sm {
    min-height: $bs-height-sm;
    :first-child {
      line-height: $bs-height-sm;
      width: $bs-height-sm;
    }
  }
}

.btn-icon {
  @include btn-icon;
}

.btn-lg.btn-icon,
.btn-group-lg > .btn.btn-icon {
  padding-left: ($bs-height-lg + $padding-large-horizontal);
}

.btn-sm.btn-icon,
.btn-group-sm > .btn.btn-icon {
  padding-left: ($bs-height-sm + $padding-small-horizontal);
}

.btn-icon-icon {
  width: $bs-height-base;
  height: $bs-height-base;
  padding: 0;
  padding-left: 0;
  border-width: 1px;
  @include btn-icon;
  :first-child {
    width: 100%!important;
    text-align: center;
    border: none;
    background-color: transparent;
  }
  &.btn-lg {
    width: $bs-height-lg;
    height: $bs-height-lg;
    padding-right: 0;
    padding-left: 0;
  }
  &.btn-sm {
    width: $bs-height-sm;
    height: $bs-height-sm;
    padding-right: 0;
    padding-left: 0;
  }
}

@mixin btn-social($color-bg, $color: rgba(255, 255, 255, .8)) {
  @include button-variant($color, $color-bg, $color-bg);
}

.btn-adn {
  @include btn-social(#d87a68);
}

.btn-bitbucket {
  @include btn-social(#205081);
}

.btn-dropbox {
  @include btn-social(#1087dd);
}

.btn-facebook {
  @include btn-social(#3b5998);
}

.btn-flickr {
  @include btn-social(#ff0084);
}

.btn-foursquare {
  @include btn-social(#f94877);
}

.btn-github {
  @include btn-social(#444);
}

.btn-google {
  @include btn-social(#dd4b39);
}

.btn-instagram {
  @include btn-social(#3f729b);
}

.btn-linkedin {
  @include btn-social(#007bb6);
}

.btn-microsoft {
  @include btn-social(#2672ec);
}

.btn-odnoklassniki {
  @include btn-social(#f4731c);
}

.btn-openid {
  @include btn-social(#f7931e);
}

.btn-pinterest {
  @include btn-social(#cb2027);
}

.btn-reddit {
  @include btn-social(#eff7ff, #000);
}

.btn-soundcloud {
  @include btn-social(#f50);
}

.btn-tumblr {
  @include btn-social(#2c4762);
}

.btn-twitter {
  @include btn-social(#55acee);
}

.btn-vimeo {
  @include btn-social(#1ab7ea);
}

.btn-vk {
  @include btn-social(#587ea3);
}

.btn-yahoo {
  @include btn-social(#720e9e);
}

.btn,
.fullcalendar .fc-button {
  @include box-shadow(none);
}
