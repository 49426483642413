/* $labels
 ------------------------------------------*/

.label {
	font-weight: $font-weight-bold;
  @include rounded($border-radius-base);
}

.label-default {
  color: $text-color;
  @include label-variant($label-default-bg);
}

.label-primary {
  @include label-variant($label-primary-bg);
}

.label-success {
  @include label-variant($label-success-bg);
}

.label-info {
  @include label-variant($label-info-bg);
}

.label-warning {
  @include label-variant($label-warning-bg);
}

.label-danger {
  @include label-variant($label-danger-bg);
}
